
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Modal, Row, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import "./home.scss";
import axios from 'axios';

const ContactForm = ({ slug }) => {
    const form = useRef();
    const navigate = useNavigate();

    const sendEmail = async (e) => {
        if (form.current.phone.value.toString().length > 9) {
            const success = await handleSaveCustomer();
            if (success) {
                // emailjs.sendForm('service_fihg5vx', 'template_es4vjjf', form.current, 'tt8EBINZkDg6uPMLi')
                //     .then(() => {
                //         toast(`Đăng ký thành công`, { type: "success", autoClose: 1000, style: { marginTop: '50px' } });
                //     }, (error) => { console.log(error.text) })
                navigate("/dang-ky-thanh-cong");
                toast(`Đăng ký thành công`, { type: "success", autoClose: 1000, style: { marginTop: '50px' } });
            } else {
                toast(`Đăng ký thất bại`, { type: "error", autoClose: 1000, style: { marginTop: '50px' } });
            }
        }
        else {
            toast(`Vui lòng nhập số điện thoại`, { type: "error", autoClose: 1000, style: { marginTop: '50px' } });
        }
    }

    const handleSaveCustomer = async () => {
        const fullName = form.current.user_name.value.toString();
        const phone = form.current.phone.value.toString();
        const message = form.current.message.value.toString();

        const data = {
            fullName: fullName,
            phone: phone,
            message: message,
            slug: slug
        }

        try {
            const res = await axios.post(`/customer/create`, data);
            if (res.data.errCode === 0) {
                return true;
            }
        } catch (error) {
            toast(`Có lỗi xảy ra`, { type: "error", autoClose: 1000, style: { marginTop: '50px' } });
        }
        return false;
    };

    return (
        <Form
            ref={form} onSubmit={() => sendEmail()}>
            <Row>
                <Col>
                    <Form.Floating className="mb-3">
                        <Form.Control type="text" name='user_name' placeholder="Tên của bạn" required className='form-contact' />
                        <Form.Label >
                            Tên của bạn<i className="text-danger"></i>
                        </Form.Label>
                        <div className="invalid-feedback"></div>
                    </Form.Floating>
                </Col>
            </Row>
            <Form.Floating className="mb-3">
                <Form.Control type="text" name='phone' placeholder="Số điện thoại" required
                    className='form-contact'
                />
                <Form.Label >
                    Số điện thoại<i className="text-danger">*</i>
                </Form.Label>
                <div className="invalid-feedback"></div>
            </Form.Floating>
            <Form.Floating className="mb-3">
                <Form.Control type="text" placeholder="Lời nhắn" name='message' className='form-contact' />
                <Form.Label >
                    Lời nhắn
                </Form.Label>
                <div className="invalid-feedback"></div>
            </Form.Floating>
            <Form.Floating className="mb-3" hidden>
                <Form.Control type="text" placeholder="slug" name='slug' className='form-contact' />
            </Form.Floating>
            <center>
                <Button
                    // type="submit"
                    variant="success"
                    onClick={() => sendEmail()}
                    className='text-center'>
                    <FontAwesomeIcon icon={faPaperPlane} /> Đăng ký tư vấn miễn phí
                </Button>
            </center>
        </Form>
    );
}

export default ContactForm;