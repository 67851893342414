import { BrowserRouter, Routes, Route } from "react-router-dom";

import "sweetalert/dist/sweetalert.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MasterLayout from "../layouts/user/MasterLayout";
import Success from "../components/user/home/Success";
import HomeIndex from "../components/user/home/HomeIndex";
import About from "../components/user/home/About";
import Detail from "../components/user/detail/Detail";
import Cost from "../components/user/home/Cost";
import Login from "../components/user/auth/Login";
import CreatePost from "../components/admin/CreatePost";
import MainLayout from "../layouts/admin/MainLayout";
import HomeAdmin from "../components/admin/dashboard/HomeAdmin";
import UserIndex from "../components/admin/user/UserIndex";
import Error404 from "../components/other/Error404";
import TopicIndex from "../components/admin/topic/TopicIndex";
import PostsIndex from "../components/admin/posts/PostsIndex";
import BlogIndex from "../components/user/blog/BlogIndex";
import BlogDetail from "../components/user/blog/BlogDetail";
import PageTopicIndex from "../components/user/topic/PageTopicIndex";
import BlogAds from "../components/user/home/BlogAds";
import ProductIndex from "../components/user/product/ProductIndex";
import CheckoutIndex from "../components/user/checkout/CheckoutIndex";
import CustomerIndex from "../components/admin/customers/CustomerIndex";

export default function router() {
  return (
    <>
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MasterLayout />}>
            <Route index element={<HomeIndex />} />
            <Route
              path="tai-sao-truong-mam-non-can-phai-su-dung-phan-mem-kafood"
              element={<BlogAds />}
            />

            <Route path="dang-ky-thanh-cong" element={<Success />} />
            <Route path="gioi-thieu" element={<About />} />
            <Route
              path="tinh-nang-phan-mem-quan-ly-suat-an-kafood"
              element={<Detail />}
            />
            <Route path="bang-gia" element={<Cost />} />
            <Route path="dang-ky-goi" element={<ProductIndex />} />
            <Route path="dang-nhap" element={<Login />} />
            <Route path="goc-chia-se" element={<BlogIndex />} />
            <Route path="bai-viet/:productSlug" element={<BlogDetail />} />
            <Route path="chinh-sach/:productSlug" element={<BlogDetail />} />
            <Route path="chu-de/:topicSlug" element={<PageTopicIndex />} />
            <Route path="dang-ky" element={<CheckoutIndex />} />
          </Route>

          <Route path="/admin" element={<MainLayout />}>
            <Route index element={<HomeAdmin />} />
            <Route path="create" element={<CreatePost />} />
            <Route path="user" element={<UserIndex />} />
            <Route path="customer" element={<CustomerIndex />} />
            <Route path="topic" element={<TopicIndex />} />
            <Route path="post" element={<PostsIndex />} />
          </Route>

          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
