import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Row, Col, Form } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faSearch, faPlus, faFileExport } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../../other/DataTables';
import swal from 'sweetalert';
import { saveAs } from "file-saver";

const CustomerIndex = () => {
    const navigate = useNavigate();
    const [customers, setCustomers] = useState([]);
    const [keyword, setKeyword] = useState('')

    useEffect(() => {
        document.title = "Khách hàng";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getCustomerAll();

    }, [navigate]);


    const getCustomerAll = async () => {
        await axios.get(`/allCustomer`, {
            params: {
                keyword: keyword
            }
        }).then((res) => {
            if (res.data.errCode === 0) {
                setCustomers(res.data.customers);
            } else {
                toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }

    const deleteCustomer = async (id, fullName) => {
        await swal({
            title: "Bạn muốn xóa Khách hàng?",
            text: fullName,
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                await axios.delete(`/customer/destroy/${id}`).then((res) => {
                    if (res.data.errCode === 0) {
                        getCustomerAll();
                        toast(res.data.message, { type: 'success' });
                    } else {
                        toast(res.data.message, { type: 'error' });
                    }
                }).catch((error) => {
                    toast(error.message, { type: 'error' });
                });
            }
        })
    }

    const handleExportExcel = async () => {
        try {
            const response = await axios.get("/customer/export-excel", {
                responseType: "arraybuffer",
            });

            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            saveAs(blob, "DanhSachKhachHang.xlsx");
        } catch (error) {
            console.error("Lỗi khi tải file Excel:", error);
            alert("Không thể tải file Excel!");
        }
    };

    const showCustomerAll = () => {
        const columns = [
            {
                name: "Họ và tên",
                selector: (row) => row.fullName,
                sortable: true,
            },
            {
                name: "Số điện thoại",
                selector: (row) => row.phone,
                sortable: true,
            },
            {
                name: "Lời nhắn",
                selector: (row) => row.message,
                sortable: true,
            },
            {
                name: "id bài viết",
                selector: (row) => row.postId,
                sortable: true,
            },
            {
                name: "Phương thức",
                selector: (row) => row.setting,
                center: true,
                cell: row => <>
                    {/* <Button onClick={() => editCustomer(row.id)} variant='outline-warning' size="sm" className="me-2" type="button">
                        <FontAwesomeIcon icon={faPencil} />
                    </Button> */}

                    <Button onClick={() => deleteCustomer(row.id, row.fullName)} variant='outline-danger' size="sm" type="button">
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </>
            },
        ];

        const data = [];

        // const selectedRows = (items) => {
        //     setselectedRows(items);
        //     setalertShow(true);
        // };

        if (customers.length > 0) {
            customers.map((item, i) => {
                data.push({
                    id: item.id,
                    fullName: item.fullName,
                    phone: item.phone,
                    message: item.message,
                    postId: item.postId
                });
                return item;
            });
        }

        return (<>
            <Row className='bg-light p-2'>
                <Col md={3}>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Tìm kiếm"
                            className="me-2"
                            onChange={(e) => setKeyword(e.target.value)}
                            aria-label="Search"
                        />
                        <Button variant="outline-secondary" onClick={getCustomerAll}><FontAwesomeIcon icon={faSearch} /></Button>
                    </Form>
                </Col>

                <Col md={4} className='ms-auto d-flex justify-content-end'>
                    <Button variant="outline-success" onClick={handleExportExcel}>
                        <FontAwesomeIcon icon={faFileExport} /> Xuất excel
                    </Button>
                </Col>
            </Row>

            <DataTables data={data} columns={columns}
            // selectedRows={selectedRows} 
            />
        </>)
    }

    return (
        <div className='container'>
            <Breadcrumb className="p-2 bg-light">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                    Tổng quan
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Khách hàng</Breadcrumb.Item>
            </Breadcrumb>

            {showCustomerAll()}
        </div>
    )
}

export default CustomerIndex;




